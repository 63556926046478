<template>
    <div class="uk-width-1-1">
        <!-- Filter -->
        <div class="uk-width-1-1 uk-grid-small uk-child-width-1-4 uk-margin-top" uk-grid>
            <div class="uk-margin-remove">
                <form class="uk-width-1-1" @submit.prevent="doSearchLiveStreamVisitorLog">
                    <label class="uk-form-label" for="fullname">Fullname</label>
                    <div class="uk-width-1-1">
                        <div class="uk-inline uk-form-controls uk-width-1-1">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                            <input 
                                id="fullname"
                                v-model="meta.fullname"
                                class="uk-input" 
                                type="search" 
                                aria-label="Search visitor" 
                                placeholder="Search visitor"
                            />
                        </div>
                    </div>
                </form>
            </div>
            <div class="uk-margin-remove">
                <label class="uk-form-label" for="sortBy">Sort by</label>
                <div class="uk-form-controls uk-width-1-1">
                    <select id="sortBy" class="uk-select" aria-label="Select" v-model="meta.sort_by" @change.prevent="doSearchLiveStreamVisitorLog">
                        <option value="default">Default</option>
                        <option value="most_chat">Most chat</option>
                        <option value="most_duration_time">Most duration time</option>
                    </select>
                </div>
            </div>
        </div>
        <!-- End filter -->

        <!-- Visitor list table -->
        <div class="uk-margin-top uk-card uk-card-default">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-striped uk-table-middle">
                    <thead>
                        <tr>
                            <th class="uk-text-nowrap uk-table-shrink uk-text-center">No.</th>
                            <th class="uk-text-left uk-text-nowrap">User</th>
                            <th class="uk-text-left uk-text-nowrap">
                                <p class="uk-margin-remove">Roles</p>
                                <p class="uk-margin-remove">Type</p> 
                            </th>
                            <th class="uk-text-left uk-text-nowrap">Email</th>
                            <th class="uk-text-left uk-text-nowrap">
                                <p class="uk-margin-remove">Telephone</p>
                                <p class="uk-margin-remove">Number</p>
                            </th>
                            <th class="uk-text-left uk-text-nowrap">
                                <p class="uk-margin-remove">Engagement</p>
                                <p class="uk-margin-remove">Duration</p>
                            </th>
                            <th class="uk-text-left uk-text-nowrap">
                                <p class="uk-margin-remove">Amount of</p>
                                <p class="uk-margin-remove">LIVE Chat</p>
                            </th>
                        </tr>
                    </thead>
                    <loading-table v-if="tableLoading" :colspan="7" />
                    <template v-else>
                        <tbody v-if="liveStreamVisitorLogData.totalDocs > 0">
                            <tr v-for="(data, index) in liveStreamVisitorLogData.docs" :key="index">
                                <td class="uk-text-nowrap uk-table-shrink uk-text-center">{{ autoIncrementNumber({ pagingCounter: liveStreamVisitorLogData.pagingCounter, index }) }}</td>
                                <td class="uk-flex uk-flex-middle uk-flex-left uk-text-left uk-text-nowrap">
                                    <img 
                                        v-if="data && data.user_photos && data.user_photos.file_url" 
                                        :src="data.user_photos.file_url" 
                                        :alt="`${data.user_fullname} photos`" 
                                        class="uk-preserve-width uk-border-circle user-photos" 
                                    />
                                    <img
                                        v-else 
                                        :src="`${images}/person.svg`"
                                        alt="Person photos" 
                                        class="uk-preserve-width uk-border-circle user-photos" 
                                    />
                                    <p class="uk-margin-remove-vertical uk-margin-small-left">{{ data && data.user_fullname || '-' }}</p>
                                </td>
                                <td class="uk-text-left uk-text-nowrap">{{ data && data.user_roles || '-' }}</td>
                                <td class="uk-text-left uk-text-nowrap" :uk-tooltip="data && data.user_email">{{ data && preview({ data: data.user_email, limit: 20 }) || '-' }}</td>
                                <td class="uk-text-left uk-text-nowrap">{{ data && data.user_phone_number || '-' }}</td>
                                <td class="uk-text-left uk-text-nowrap">{{ data && engagementDurationFormatter(data.engagement_duration) || 0 }}</td>
                                <td class="uk-text-left uk-text-nowrap">{{ data && data.amount_of_live_chat || '-' }}</td>
                            </tr>
                        </tbody>
                        <empty-table v-else :colspan="7" />
                    </template>
                </table>
            </div>
            <pagination
                :total-data="liveStreamVisitorLogData.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>
        <!-- End visitor list table -->
    </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';
import { mapActions } from 'vuex';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import formatter from '@/utils/formatter';

export default {
    name: 'LoginVisitorTab',
    components: {
        Pagination: () => import('@/components/globals/Pagination'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        LoadingTable: () => import('@/components/globals/tables/Loading'),
    },
    data() {
        return {
            internetConnection: true,
            tableLoading: false,
            images: PREFIX_IMAGE,
            liveStreamVisitorLogData: {
                docs: [],
                totalDocs: 0,
                pagingCounter: 0
            },
            meta: {
                limit: 10,
                page: 1,
                live_stream_id: '',
                fullname: '',
                sort_by: 'default'
            }
        };
    },
    watch: {
        async meta() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.tableLoading = true;
                await this.setLiveStreamVisitorLogData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
    },
    async mounted() {
        try {
            if (!navigator.onLine) return this.internetConnection = false;
            await this.setLiveStreamVisitorLogData();
        } catch (error) {
            notificationDanger(error);
        }
    },
    methods: {
        ...mapActions({
            getLiveStreamVisitorLogListPaginate: 'liveStreamVisitorLogV4/getLiveStreamVisitorLogListPaginate'
        }),
        async setLiveStreamVisitorLogData() {
            try {
                this.tableLoading = true;
                this.meta.live_stream_id = this.$route.params.id;
                const response = await this.getLiveStreamVisitorLogListPaginate(this.meta);
                if (response && response.data && response.data.status == 'OK' && response.data.result) {
                    const docs = response.data.result && response.data.result.docs || [];
                    const totalDocs = response.data.result && response.data.result.totalDocs || 0;
                    const pagingCounter = response.data.result && response.data.result.pagingCounter || 0;

                    this.liveStreamVisitorLogData.docs = docs;
                    this.liveStreamVisitorLogData.totalDocs = totalDocs;
                    this.liveStreamVisitorLogData.pagingCounter = pagingCounter;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        async doSearchLiveStreamVisitorLog() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.tableLoading = true;
                this.meta.limit = 10;
                this.meta.page = 1;
                await this.setLiveStreamVisitorLogData();
            } catch (error) {
                notificationDanger(error);
            }
        },
        autoIncrementNumber({ pagingCounter, index }) {
            return formatter.autoIncrementNumberPagination({ pagingCounter, index });
        },  
        preview({ data, limit }) {
            if (typeof data !== 'string') throw new Error('Data is not string');
            
            if (data.length < limit) {
                return data;
            } else {
                return data.substring(0, limit)+'...';
            }
        }, 
        engagementDurationFormatter(milliseconds) {
            let seconds = Math.floor(milliseconds / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);

            seconds = seconds % 60;
            minutes = minutes % 60;
            hours = hours % 24;
            
            if (minutes == 0) {
                return '-';
            } else if (hours == 0) {
                return `${minutes} Minutes`;
            } else {
                return `${hours} Hours ${minutes} Minutes`;
            }
        },
        changeLimit(e) {
            if (!navigator.onLine) return this.internetConnection = false;
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            if (!navigator.onLine) return this.internetConnection = false;
            this.meta = {
                ...this.meta,
                page: value
            };
        }
    }
};
</script>

<style scoped>
.user-photos {
    width: 100%;
    height: 100%;
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    object-fit: cover;
    object-position: center;
}
</style>
